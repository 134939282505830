<template>
    <div class="page login-page">
        <BaseHeader
            :sign-up="['register', 'pass-change'].includes(mode)"
            :sign-in="['forgot', 'sign-in', 'failed'].includes(mode)"
        />
        <div class="login-page-content">
            <div
                class="login-page__sign-in-form"
                v-if="mode === 'sign-in'"
                :class="{'login-page__sign-in-form_wide': useEmail}"
            >
                <template v-if="!useEmail && !onlyEmail">
                    <div class="form-title">
                        {{ $t('sign_in') }}
                    </div>
                    <div class="login-buttons">
                        <div
                            class="login-button login-button_google"
                            onclick="App.reachGoal('clickButtonGoogleRegistrationPage'); return true;"
                            @click.prevent="loginGoogle()">
                            <div class="login-button-icon">
                                <svg xmlns="http://www.w3.org/2000/svg"
                                     width="18"
                                     height="18"
                                     viewBox="0 0 18 18">
                                    <g fill="none"
                                       fill-rule="evenodd">
                                        <path fill="#EA4335"
                                              d="M9 3.48c1.69 0 2.83.73 3.48 1.34l2.54-2.48C13.46.89 11.43 0 9 0 5.48 0 2.44 2.02.96 4.96l2.91 2.26C4.6 5.05 6.62 3.48 9 3.48z"/>
                                        <path fill="#4285F4"
                                              d="M17.64 9.2c0-.74-.06-1.28-.19-1.84H9v3.34h4.96c-.1.83-.64 2.08-1.84 2.92l2.84 2.2c1.7-1.57 2.68-3.88 2.68-6.62z"/>
                                        <path fill="#FBBC05"
                                              d="M3.88 10.78A5.54 5.54 0 0 1 3.58 9c0-.62.11-1.22.29-1.78L.96 4.96A9.008 9.008 0 0 0 0 9c0 1.45.35 2.82.96 4.04l2.92-2.26z"/>
                                        <path fill="#34A853"
                                              d="M9 18c2.43 0 4.47-.8 5.96-2.18l-2.84-2.2c-.76.53-1.78.9-3.12.9-2.38 0-4.4-1.57-5.12-3.74L.97 13.04C2.45 15.98 5.48 18 9 18z"/>
                                        <path d="M0 0h18v18H0z"/>
                                    </g>
                                </svg>
                            </div>
                            <div class="login-button-text">Continue with Google</div>
                        </div>
                        <div class="login-button login-button_facebook"
                             onclick="App.reachGoal('clickButtonFacebookRegistrationPage'); return true;"
                             @click.prevent="loginFacebook()">
                            <div class="login-button-icon">
                                <svg xmlns="http://www.w3.org/2000/svg"
                                     width="17"
                                     height="17"
                                     viewBox="0 0 17 17">
                                    <path fill="#3B5998"
                                          fill-rule="evenodd"
                                          d="M.709 0A.709.709 0 0 0 0 .708v15.584c0 .391.317.708.709.708h8.323v-6.375H6.907V7.969h2.125V5.844c0-2.196 1.39-3.276 3.348-3.276.938 0 1.745.07 1.98.1v2.295h-1.358c-1.066 0-1.314.507-1.314 1.25v1.756h2.656l-.531 2.656h-2.125L11.73 17h4.562a.708.708 0 0 0 .708-.708V.708A.708.708 0 0 0 16.292 0H.709z"/>
                                </svg>
                            </div>
                            <div class="login-button-text">Continue with Facebook</div>
                        </div>
                        <div class="login-or">or</div>
                        <div class="login-button login-button_email"
                             @click.prevent="loginEmail()">
                            <div class="login-button-icon">
                                <svg xmlns="http://www.w3.org/2000/svg"
                                     width="20"
                                     height="16"
                                     viewBox="0 0 20 16">
                                    <g fill="none"
                                       fill-rule="evenodd"
                                       stroke="#696778"
                                       stroke-linecap="round"
                                       stroke-linejoin="round"
                                       stroke-width="2">
                                        <path
                                            d="M2.8 1h14.4c.99 0 1.8.787 1.8 1.75v10.5c0 .963-.81 1.75-1.8 1.75H2.8c-.99 0-1.8-.787-1.8-1.75V2.75C1 1.787 1.81 1 2.8 1z"/>
                                        <path d="M19 3l-9 7-9-7"/>
                                    </g>
                                </svg>
                            </div>
                            <div class="login-button-text">Sign in with Email</div>
                        </div>
                    </div>
                </template>
                <template v-else-if="useEmail || onlyEmail">
                    <div v-if="!onlyEmail" class="back-btn" @click="useEmail = false">
                        <svg width="12" height="19" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M7.75 1.59A.978.978 0 0 0 7.74.276.853.853 0 0 0 6.496.264L.26 6.84a.977.977 0 0 0 0 1.328l6.22 6.567a.853.853 0 0 0 1.244-.011.978.978 0 0 0 .01-1.316L2.142 7.505 7.751 1.59z"
                                transform="translate(2 2)"
                                fill-rule="nonzero">
                            </path>
                        </svg>
                    </div>
                    <div class="form-title">
                        {{ $t('sign_in') }}
                    </div>
                    <form autocomplete="on" @submit.prevent="login">
                        <label>E-mail</label>
                        <input class="mb-3" type="email" required name="email" v-model="email"/>

                        <label>
                            {{ $t('password') }}
                            <router-link class="login-page__forgot-link" to="/login?mode=forgot">
                                {{ $t('forgot_password') }}
                            </router-link>
                        </label>
                        <input type="password" name="password" required v-model="password"/>

                        <button type="submit" class="btn">
                            {{ $t('enter') }}
                        </button>
                    </form>
                </template>
            </div>
            <div
                class="login-page__register-form"
                v-else-if="mode === 'register'"
                :class="{'login-page__register-form_wide': useEmail}"
            >
                <template v-if="!useEmail && !onlyEmail">
                    <div class="back-btn" @click="$router.push('/login')">
                        <svg width="12"
                             height="19"
                             xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M7.75 1.59A.978.978 0 0 0 7.74.276.853.853 0 0 0 6.496.264L.26 6.84a.977.977 0 0 0 0 1.328l6.22 6.567a.853.853 0 0 0 1.244-.011.978.978 0 0 0 .01-1.316L2.142 7.505 7.751 1.59z"
                                transform="translate(2 2)"
                                fill-rule="nonzero"></path>
                        </svg>
                    </div>
                    <div class="form-title">Create your account</div>
                    <div class="login-buttons">
                        <div class="login-button login-button_google"
                             onclick="App.reachGoal('clickButtonGoogleLoginPage'); return true;"
                             @click.prevent="loginGoogle()">
                            <div class="login-button-icon">
                                <svg xmlns="http://www.w3.org/2000/svg"
                                     width="18"
                                     height="18"
                                     viewBox="0 0 18 18">
                                    <g fill="none"
                                       fill-rule="evenodd">
                                        <path fill="#EA4335"
                                              d="M9 3.48c1.69 0 2.83.73 3.48 1.34l2.54-2.48C13.46.89 11.43 0 9 0 5.48 0 2.44 2.02.96 4.96l2.91 2.26C4.6 5.05 6.62 3.48 9 3.48z"/>
                                        <path fill="#4285F4"
                                              d="M17.64 9.2c0-.74-.06-1.28-.19-1.84H9v3.34h4.96c-.1.83-.64 2.08-1.84 2.92l2.84 2.2c1.7-1.57 2.68-3.88 2.68-6.62z"/>
                                        <path fill="#FBBC05"
                                              d="M3.88 10.78A5.54 5.54 0 0 1 3.58 9c0-.62.11-1.22.29-1.78L.96 4.96A9.008 9.008 0 0 0 0 9c0 1.45.35 2.82.96 4.04l2.92-2.26z"/>
                                        <path fill="#34A853"
                                              d="M9 18c2.43 0 4.47-.8 5.96-2.18l-2.84-2.2c-.76.53-1.78.9-3.12.9-2.38 0-4.4-1.57-5.12-3.74L.97 13.04C2.45 15.98 5.48 18 9 18z"/>
                                        <path d="M0 0h18v18H0z"/>
                                    </g>
                                </svg>
                            </div>
                            <div class="login-button-text">Continue with Google</div>
                        </div>
                        <div class="login-button login-button_facebook"
                             onclick="App.reachGoal('clickButtonFacebookLoginPage'); return true;"
                             @click.prevent="loginFacebook()">
                            <div class="login-button-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17">
                                    <path fill="#3B5998"
                                          fill-rule="evenodd"
                                          d="M.709 0A.709.709 0 0 0 0 .708v15.584c0 .391.317.708.709.708h8.323v-6.375H6.907V7.969h2.125V5.844c0-2.196 1.39-3.276 3.348-3.276.938 0 1.745.07 1.98.1v2.295h-1.358c-1.066 0-1.314.507-1.314 1.25v1.756h2.656l-.531 2.656h-2.125L11.73 17h4.562a.708.708 0 0 0 .708-.708V.708A.708.708 0 0 0 16.292 0H.709z"/>
                                </svg>
                            </div>
                            <div class="login-button-text">Continue with Facebook</div>
                        </div>
                        <div class="login-or">or</div>
                        <div class="login-button login-button_email"
                             @click.prevent="loginEmail()">
                            <div class="login-button-icon">
                                <svg xmlns="http://www.w3.org/2000/svg"
                                     width="20"
                                     height="16"
                                     viewBox="0 0 20 16">
                                    <g fill="none"
                                       fill-rule="evenodd"
                                       stroke="#696778"
                                       stroke-linecap="round"
                                       stroke-linejoin="round"
                                       stroke-width="2">
                                        <path
                                            d="M2.8 1h14.4c.99 0 1.8.787 1.8 1.75v10.5c0 .963-.81 1.75-1.8 1.75H2.8c-.99 0-1.8-.787-1.8-1.75V2.75C1 1.787 1.81 1 2.8 1z"/>
                                        <path d="M19 3l-9 7-9-7"/>
                                    </g>
                                </svg>
                            </div>
                            <div class="login-button-text">Sign up with Email</div>
                        </div>
                    </div>
                </template>
                <template v-else-if="useEmail || onlyEmail">
                    <div class="back-btn" v-if="!onlyEmail" @click="useEmail=false">
                        <svg width="12"
                             height="19"
                             xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M7.75 1.59A.978.978 0 0 0 7.74.276.853.853 0 0 0 6.496.264L.26 6.84a.977.977 0 0 0 0 1.328l6.22 6.567a.853.853 0 0 0 1.244-.011.978.978 0 0 0 .01-1.316L2.142 7.505 7.751 1.59z"
                                transform="translate(2 2)"
                                fill-rule="nonzero"></path>
                        </svg>
                    </div>
                    <div class="form-title">
                        {{ $t('registration') }}
                    </div>
                    <form autocomplete="off" @submit.prevent="register">
                        <label>E-mail</label>
                        <input class="mb-3" type="email" required name="email" v-model="email"/>

                        <label>{{ $t('name') }}</label>
                        <input class="mb-3" type="text" required name="name" v-model="name"/>

                        <label>{{ $t('password') }}</label>
                        <input class="mb-3" type="password" required name="password" v-model="password"/>

                        <label>{{ $t('confirmPassword') }}</label>
                        <input type="password" name="password_confirmation" required v-model="passwordConfirmation"/>

                        <button class="btn">
                            {{ $t('submit') }}
                        </button>
                    </form>
                </template>
            </div>
            <div
                class="login-page__forgot-form"
                v-else-if="mode === 'forgot'"
                :class="{'login-page__forgot-form_wide': true}"
            >
                <div class="back-btn" @click="$router.replace({query: { }});">
                    <svg width="12" height="19" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M7.75 1.59A.978.978 0 0 0 7.74.276.853.853 0 0 0 6.496.264L.26 6.84a.977.977 0 0 0 0 1.328l6.22 6.567a.853.853 0 0 0 1.244-.011.978.978 0 0 0 .01-1.316L2.142 7.505 7.751 1.59z"
                            transform="translate(2 2)"
                            fill-rule="nonzero"></path>
                    </svg>
                </div>
                <div class="form-title">
                    {{ $t('resetPassword') }}
                </div>
                <form @submit.prevent="forgot">
                    <label>E-mail</label>
                    <input type="email" name="email" v-model="email" required/>

                    <button type="submit" class="btn">
                        {{ $t('reset') }}
                    </button>
                </form>
            </div>
            <div class="login-page__pass-change-form"
                 v-else-if="mode === 'pass-change'"
                 :class="{'login-page__pass-change-form_wide': true}">
                <div class="form-title">{{ $t('enterNewPassword') }}</div>
                <form autocomplete="off" @submit.prevent="passChange">
                    <label>{{ $t('verificationCode') }}</label>
                    <input class="mb-3" type="text" required name="code" v-model="code"/>

                    <label>{{ $t('newPassword') }}</label>
                    <input class="mb-3" type="password" required name="password" v-model="password"/>

                    <label>{{ $t('confirmPassword') }}</label>
                    <input class="mb-3"
                           type="password"
                           required
                           name="password_confirmation"
                           v-model="passwordConfirmation"/>

                    <button type="submit" class="btn">
                        {{ $t('submit') }}
                    </button>
                </form>
            </div>
            <div class="login-page__message" :class="{'show': message}" @click="message = false">
                {{ $t(errorMsg) }}
            </div>
        </div>
        <BaseFooter/>
    </div>
</template>

<script>
/* eslint-disable */
import Ajax from '@/mixins/Ajax';
import Toast from '@/mixins/Toast';
import common from '@/mixins/Common';
import Analytics from '@/mixins/Analytics';
import BaseHeader from '@/components/Layouts/BaseHeader';
import BaseFooter from '@/components/Layouts/BaseFooter';
import { apiPost } from '@/models/Api';
import { UserSingleton } from '@/models/UserSingleton';

export default {
    name: 'Login',
    components: {
        BaseHeader,
        BaseFooter
    },
    mixins: [Analytics, Ajax, Toast],
    data() {
        return {
            who: 'login',
            isPage: true,
            mode: 'sign-in', // register / forgot / pass-change
            useEmail: true,
            onlyEmail: true, // ToDo: вернуть возможность регистрации и авторизации через соц. сети
            email: '',
            name: '',
            password: '',
            passwordConfirmation: '',
            code: '', // change password verification code
            message: false,
            errorMsg: '',
            loginPage: true,
            installer: {
                osName: '',
                url: '',
                size: 0
            },
            engine: common.detectEngine(),
            os: common.detectOs()
        };
    },
    watch: {
        mode() {
            this.message = false;
            // ToDo: вернуть переключение типа страницы при востановлении других регистраций
            // this.useEmail = false;
        },
        useEmail() {
            this.message = false;
        },
        '$route.query.mode'() {
            this.changeMode(this.$route.query.mode);
        }
    },
    methods: {
        async register() {
            App.reachGoal('formRegistration');

            const data = {
                email: this.email,
                password: this.password,
                // eslint-disable-next-line camelcase
                password_confirmation: this.passwordConfirmation,
                name: this.name
            };

            const res = await apiPost('/api/v1/register', data);

            if(res) {
                UserSingleton.saveToken(res);
                const user = await UserSingleton.getInstance();

                if(user) {
                    await this.$store.dispatch('autoLogin');
                    await this.$router.push('/account');
                }
            }
        },
        async login() {
            App.reachGoal('formLogin');

            const data = {
                email: this.email,
                password: this.password
            };

            const res = await apiPost('/api/v1/login', data);

            if(res) {
                UserSingleton.saveToken(res);
                const user = await UserSingleton.getInstance();

                if(user) {
                    await this.$store.dispatch('autoLogin');
                    await this.$router.push('/account');
                }
            }
        },
        loginFacebook() {
            window.location = 'login/facebook';
        },
        loginGoogle() {
            window.location = 'login/google';
        },
        loginEmail() {
            this.useEmail = true;
        },
        async forgot() {
            const res = await apiPost('/api/v1/password/email', { email: this.email });

            if(res) {
                await this.$router.replace({query: { mode: 'pass-change' }});
                this.showSuccessToast('forgotPassCodeSent', 'top-center');
            }
        },
        async passChange() {
            const res = await apiPost(`/api/v1/password/update`,
                {
                    code: this.code,
                    email: this.email,
                    password: this.password,
                    password_confirmation: this.passwordConfirmation
                }
            );

            if(res) {
                await this.$router.replace({query: { }});

                this.showSuccessToast('passwordSuccessfullyChanged', 'top-center');
            }
        },
        showError(msg) {
            this.errorMsg = msg;
            this.message = true;
        },
        changeMode(mode) {
            if(mode === 'register') {
                this.mode = 'register';
            } else if(mode === 'forgot') {
                this.mode = 'forgot';
            } else if(mode === 'failed') {
                this.showError('Login failed, try again');
            } else if(mode === 'pass-change') {
                this.mode = 'pass-change';
            } else {
                this.mode = 'sign-in';
            }
        }
    },
    created() {
        this.changeMode(this.$route.query.mode);
    }
};
</script>

<style lang="scss">
@import '@/assets/scss/vars.scss';
@import '@/assets/scss/mixins.scss';
@import '@/assets/scss/common.scss';

body {
    font-family: 'Montserrat', sans-serif;
}

h1 {
    color: #aaa;
}

.login-page {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    top: 56px;
    right: 0;
    bottom: 0;
    left: 0;
    height: auto;
    min-height: 0;
    overflow: auto;
    box-sizing: border-box;

    .btn {
        margin: 36px 0 0 0;
        font-weight: 500;
    }

    .back-btn {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 0;
        left: -50px;
        height: 42px;
        width: 42px;
        border-radius: 50%;
        transition: all .3s ease;
        cursor: pointer;

        @media (max-width: 800px) {
            left: -36px;
        }
        @media (max-width: 400px) {
            display: none;
        }

        &:hover {
            background: #3351;
            @media (max-width: 800px) {
                background: transparent;
            }
        }

        svg {
            position: relative;
            display: block;
            fill: $accent_color1;
        }
    }

    &__footer {
        position: relative;
        display: block;
        // margin-top: auto;
        // position:absolute;
        // display:block;
        // right:0;
        // bottom:0;
        // left:0;
        font-size: 12px;

        @media (max-height: 800px) {
            position: relative;
        }

        &-links {
            position: relative;
            display: flex;
            justify-content: center;
            padding: 8px 15px;

            a {
                position: relative;
                display: block;
                margin: 0 10px;
            }
        }
    }

    &__warning {
        position: relative;
        display: block;
        background: #e5e3ea;
        color: #779;
        text-align: center;
        vertical-align: middle;
        @include global_padding;
        padding-top: 8px;
        padding-bottom: 8px;
        box-sizing: border-box;
        overflow: hidden;
        transition: all .3s ease;

        &_hid {
            padding-top: 0;
            padding-bottom: 0;
            max-height: 0;
        }
    }

    &__cookies {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        background: $accent_color1;
        color: #fff;
        text-align: center;
        padding-top: 0;
        padding-bottom: 0;
        vertical-align: middle;
        max-height: 0;
        box-sizing: border-box;
        overflow: hidden;
        transition: all .3s ease;
        @include global_padding;

        &_active {
            max-height: 120px;
            // padding-top: 8px;
            // padding-bottom: 8px;
        }

        span {
            position: relative;
            display: block;
            margin: 8px;
        }

        &-btn.btn {
            position: relative;
            display: inline-block;
            background: rgba(255, 255, 255, .1);
            color: #fff;
            margin: 8px;
            padding: 7px 17px;
            font-weight: bold;
            font-size: 12px;

            // @media (max-width:960px) {
            //   display: block;
            //   margin: 10px auto;
            // }
        }
    }

    &-content {
        position: relative;
        display: flex;
        // align-self:center;
        margin: auto;
        padding: 48px 0 24px;
        @include global_padding;
        box-sizing: border-box;
        width: 100%;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .form-title {
        font-size: 32px;
        margin-bottom: 36px;
        font-weight: 500;
    }

    &__sign-in-form, &__register-form, &__forgot-form {
        display: block;
        max-width: 500px;
        position: relative;

        &_wide {
            width: 100%;
        }

        @media (max-width: 400px) {
            width: 100%;
        }

        form {
            position: relative;
            display: block;
            width: 100%;

            input {
                border-radius: 0;
            }

            input[type=text], input[type=password], input[type=email] {
                position: relative;
                display: block;
                height: 24px;
                padding: 5px 0;
                width: 100%;
                background: none;
                border: none;
                border-bottom: 2px solid #ddd;
                outline: none;
                font-size: 16px;
            }
        }
    }

    &__message {
        position: relative;
        display: block;
        max-width: 500px;
        width: 100%;
        background: #e3b4aa;
        color: #7a4b4a;
        padding: 10px 20px;
        box-sizing: border-box;
        border-radius: 3px;
        opacity: 0;
        transition: opacity .3s ease;
        margin-top: 10px;
        font-size: 14px;

        &.show {
            opacity: 1;
        }
    }

    &__forgot-link {
        position: absolute;
        top: 0;
        right: 0;
    }
}

.header {
    &__link {
        // position:relative;
        // display:block;
        // border-bottom: 2px solid $accent_color1;
        margin-left: 30px;

        &_download {
            text-decoration: none;
            border-radius: 12px;
            // border:1px solid $accent_color1;
            border: 0;
            background: #eef7;
            padding: 7px 20px 7px 10px;
            // font-weight: 500;
            transition: all .2s ease;

            @media (max-width: 1024px) {
                display: none;
            }

            svg {
                position: relative;
                display: inline-block;
                vertical-align: middle;
                margin-right: 5px;
                stroke: $accent_color1;
                transition: all .2s ease;
            }

            &:hover {
                background: $accent_color1;
                color: #fff;

                svg {
                    stroke: #fff;
                }
            }
        }
    }
}

.login-button {
    position: relative;
    height: 42px;
    background-color: #fff;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.1);
    margin-bottom: 16px;
    transition: all .3s ease;

    &:hover {
        background-color: #fff5;
    }

    &s {
        width: 338px;
        max-width: 100%;
    }

    &-icon {
        position: absolute;
        left: 16px;
        top: 50%;
        transform: translateY(-39%);
    }

    &-text {
        position: relative;
        margin-left: auto;
        margin-right: auto;
        font-size: 14px;
        font-weight: 500;
        color: #303035;
    }
}

.login-or {
    width: 15px;
    height: 18px;
    font-family: 'Montserrat';
    font-size: 14px;
    font-weight: 500;
    color: #a19eab;
    position: relative;
    margin-bottom: 16px;
    margin-left: auto;
    margin-right: auto;

    &:after, &:before {
        content: '';
        width: 50px;
        height: 1px;
        background-color: #a19eab;
        position: absolute;
        top: 9px;
    }

    &:after {
        right: -63px;
    }

    &:before {
        left: -63px;
    }
}
</style>

<i18n>
{
    "en": {
        "sign_in": "Sign in",
        "registration": "Registration",
        "password": "Password",
        "confirmPassword": "Confirm password",
        "newPassword": "New password",
        "resetPassword": "Reset password",
        "reset": "Reset",
        "submit": "Submit",
        "name": "Name",
        "forgot_password": "Forgot password",
        "enter": "Enter",
        "verificationCode": "Verification code",
        "enterNewPassword": "Enter new password",
        "LoginError": "Login/Password incorect",
        "RegisterError": "Register error, try again later",
        "failed_user_login": "Invalid login password pair",
        "forgotPassCodeSent": "Verification code sent to EMail",
        "passwordSuccessfullyChanged": "Password successfully changed"
    },
    "ru": {
        "sign_in": "Войти",
        "registration": "Регистрация",
        "password": "Пароль",
        "newPassword": "Новый пароль",
        "confirmPassword": "Повторите пароль",
        "resetPassword": "Сбросить пароль",
        "reset": "Сбросить",
        "submit": "Отправить",
        "name": "Имя",
        "forgot_password": "Забыли пароль?",
        "enter": "Войти",
        "verificationCode": "Проверочный код",
        "enterNewPassword": "Введите новый пароль",
        "LoginError": "Неверная пара логин/пароль",
        "RegisterError": "Ошибка регистрации, повторите позже",
        "failed_user_login": "Неверная пара логин/пароль",
        "forgotPassCodeSent": "Проверочный код отправлен на EMail",
        "passwordSuccessfullyChanged": "Пароль успешно изменен"
    }
}
</i18n>
